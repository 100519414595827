import o from "react";
import { createRoot as c } from "react-dom/client";
import m from "@r2wc/core";
function f(t, e, r) {
  const n = c(t), u = o.createElement(e, r);
  return n.render(u), {
    root: n,
    ReactComponent: e
  };
}
function i({ root: t, ReactComponent: e }, r) {
  const n = o.createElement(e, r);
  t.render(n);
}
function a({ root: t }) {
  t.unmount();
}
function s(t, e = {}) {
  return m(t, e, { mount: f, update: i, unmount: a });
}
export {
  s as default
};
